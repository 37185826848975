import React from "react";
import _get from "lodash/get";
import _extend from "lodash/extend";

import { Text, Divider, FormControl, FormLabel, Input } from "@chakra-ui/react";

function UserDetailsForm(props) {
  const { credentials, setCredentials } = props;
  // const [showForm, setShowForm] = useState("signup");
  // const [credentials, setCredentials] = useState({
  //   firstName: "",
  //   lastName: "",
  //   email: "",
  //   password: "",
  //   password2: "",
  // });

  // const [error, setError] = useState("");
  // const [success, setSuccess] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);

  // const handleShowForm = (currentForm) => setShowForm(currentForm);

  const handleOnChange = (e) => {
    const targetInput = _get(e, "target.name", "");
    const targetInputValue = _get(e, "target.value", "");
    const values = credentials;

    const updatedInputValues = _extend(values, {
      ...values,
      [targetInput]: targetInputValue,
    });

    setCredentials(updatedInputValues);
  };

  const SignUpForm = () => {
    return (
      <>
        <FormControl isRequired>
          <FormLabel>First Name:</FormLabel>
          <Input
            type="text"
            placeholder="First Name"
            name="firstName"
            h="52px"
            onChange={handleOnChange}
          />
        </FormControl>
        <FormControl isRequired my="10px">
          <FormLabel>Last Name:</FormLabel>
          <Input
            type="text"
            placeholder="Last Name"
            name="lastName"
            h="52px"
            onChange={handleOnChange}
          />
        </FormControl>
        <FormControl isRequired my="10px">
          <FormLabel>Email:</FormLabel>
          <Input
            type="email"
            placeholder="you@yourdomain.com"
            name="email"
            h="52px"
            onChange={handleOnChange}
          />
        </FormControl>
        <FormControl isRequired my="10px">
          <FormLabel>Password:</FormLabel>
          <Input
            type="password"
            placeholder="******"
            name="password"
            h="52px"
            onChange={handleOnChange}
          />
        </FormControl>
        <FormControl isRequired my="10px">
          <FormLabel>Confirm Password:</FormLabel>
          <Input
            type="password"
            placeholder="******"
            name="password2"
            onChange={handleOnChange}
            h="52px"
          />
        </FormControl>
        {/* <Box my="15px">
          <StyledText display="flex">
            Already have an account?
            <span onClick={() => handleShowForm("login")}>Click to Login</span>
          </StyledText>
        </Box> */}

        {/* <Button type="submit" w="100%" isLoading={isLoading}>
          Create Account
        </Button> */}
      </>
    );
  };

  return (
    <div>
      <Text mb="10px" fontWeight="bold">
        STEP 1
      </Text>
      <Text>Your Details</Text>
      <Divider my="10px" />
      {/* {showForm === "login" ? LoginForm() : SignUpForm()} */}
      {SignUpForm()}
    </div>
  );
}

export default UserDetailsForm;

// const LoginForm = () => {
//   return (
//     <form autoComplete="off">
//       <FormControl isRequired my="10px">
//         <FormLabel>Email:</FormLabel>
//         <Input
//           type="email"
//           placeholder="you@yourdomain.com"
//           name="email"
//           h="52px"
//         />
//       </FormControl>
//       <FormControl isRequired my="10px">
//         <FormLabel>Password:</FormLabel>
//         <Input type="password" placeholder="******" name="password" h="52px" />
//       </FormControl>
//       <Box my="15px">
//         <StyledText display="flex">
//           Need an Account?
//           <span>Click to Sign up</span>
//         </StyledText>
//       </Box>
//       <Button type="submit" w="100%">
//         Login
//       </Button>
//     </form>
//   );
// };
