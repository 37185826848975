import React from "react";

import { Box, Flex, Text } from "@chakra-ui/react";
import { StyledButton as Button } from "./styles";
import stripeProductsInfo from "./stripeProductsInfo";

function PlanSelect(props) {
  const { selectedPlan: product, handlePlanSelect } = props;

  const selectedPlan = product?.subscription.toLowerCase();

  return (
    <Box mt="20px">
      <Text mb="10px" fontWeight="bold">
        STEP 2
      </Text>

      <Text textAlign="center" my="20px">
        Select a plan:
      </Text>

      <Flex justifyContent="center">
        <Box textAlign="center" mr="20px" minW={{ base: "130px", md: "130px" }}>
          <Text fontSize="24px">Basic Plan</Text>
          $69/ month <br />
          <Button
            mt="10px"
            onClick={() => handlePlanSelect(stripeProductsInfo["basic"])}
            background={selectedPlan === "basic" && "#6cb2eb"}
            color={selectedPlan === "basic" && "white"}
            _hover={{
              background: selectedPlan === "basic" && "#6cb2eb",
            }}
          >
            {selectedPlan === "basic" ? "Selected" : "Select"}
          </Button>
        </Box>

        <Box textAlign="center" minW={{ base: "130px", md: "130px" }}>
          <Text fontSize="24px">Pro Plan</Text>
          $115/ month <br />
          <Button
            mt="10px"
            onClick={() => handlePlanSelect(stripeProductsInfo["pro"])}
            background={selectedPlan === "pro" && "#6cb2eb"}
            color={selectedPlan === "pro" && "white"}
            _hover={{
              background: selectedPlan === "pro" && "#6cb2eb",
            }}
          >
            {selectedPlan === "pro" ? "Selected" : "Select"}
          </Button>
        </Box>
        <Box></Box>
      </Flex>
    </Box>
  );
}

export default PlanSelect;
