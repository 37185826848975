import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import "firebase/storage";
import dotenv from "dotenv";
dotenv.config();

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_apiKey,
//   authDomain: process.env.REACT_APP_authDomain,
//   projectId: process.env.REACT_APP_projectId,
//   storageBucket: process.env.REACT_APP_storageBucket,
//   messagingSenderId: process.env.REACT_APP_messagingSenderId,
//   appId: process.env.REACT_APP_appId,
//   measurementId: process.env.REACT_APP_measurementId,
// };

// const firebaseConfig = {
//   apiKey: "AIzaSyAU1TH5wKokBQHqxLCH1cdXp5Dq83advKo",
//   authDomain: "hello-inspections-dev-39fec.firebaseapp.com",
//   databaseURL: "https://hello-inspections-dev-39fec.firebaseio.com",
//   projectId: "hello-inspections-dev-39fec",
//   storageBucket: "hello-inspections-dev-39fec.appspot.com",
//   messagingSenderId: "657634266591",
//   appId: "1:657634266591:web:58ae17c7ae9f5420dcc86a",
//   measurementId: "G-14W78B9XMW",
// };

const firebaseConfig = {
  apiKey: "AIzaSyDUZcCdmlG3efbDr8loHRMjApXWtVvq6Ks",
  authDomain: "hello-inspections-59c29.firebaseapp.com",
  databaseURL: "https://hello-inspections-59c29.firebaseio.com",
  projectId: "hello-inspections-59c29",
  storageBucket: "hello-inspections-59c29.appspot.com",
  messagingSenderId: "301437582297",
  appId: "1:301437582297:web:c16be0fee12670e492f7c8",
  measurementId: "G-1FYY1Q26X4",
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
} else {
  firebase.app(); // if already initialized, use that one
}
export default firebase;
export const firebaseRef = firebase;
export const auth = firebase.auth;
export const firestore = firebase.firestore();
export const storageRef = firebase.storage().ref();
export const database = firebase.database();
