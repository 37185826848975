import styled from "@emotion/styled";
import { Box } from "@chakra-ui/react";
import { FaGift } from "react-icons/fa";

export const ImgContainer = styled(Box)`
  ${"" /* background-size: cover; */}
  ${"" /* background-position: center center; */}
  height: 250px;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("https://register.helloinspections.com/img/mainbanner.jpg");
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("https://register.helloinspections.com/img/mainbanner.jpg");
  margin-bottom: -9rem;
  background-repeat: no-repeat !important;
  background-size: 100%;
  position: repeat;
`;

export const PlanBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  border: 0px solid #dae1e7;
  -webkit-box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);

  ${"" /* min-height: 41vh; */}
  width: 39vw;
  padding: 3rem;
  ul {
    list-style: none;
    text-align: center;
  }

  li {
    font-weight: 300;
    font-size: 1.25rem;
  }

  ${
    "" /* h2 {
    font-weight: bold;
  } */
  }

  @media (max-width: 768px) {
    width: 80vw;

    margin-bottom: 30px;
    ${"" /* min-height: 36vh; */}
    width: 83vw;
  }
`;

export const BannerPlansContainer = styled(Box)`
  display: block;
  direction: column;

  @media (max-width: 768px) {
    display: flex !important;
    align-items: center;
    flex-direction: column;

    .bannerText {
      margin-top: -3.5rem;
      line-height: 38px;
      margin-bottom: 8rem;
    }
  }
`;

export const GiftIcon = styled(FaGift)`
  height: 48px;
  width: auto;
`;
