import React from "react";
import { FormLabel } from "@chakra-ui/react";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";

import "./stripe.css";

const CARD_ELEMENT_OPTIONS = {
  style: {
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

export default function CardInput() {
  return (
    <div className="stripeElements">
      <FormLabel>Card Number:</FormLabel>
      <CardNumberElement options={CARD_ELEMENT_OPTIONS} />
      {/* <Flex w="100%"> */}
      <FormLabel>Card Expiry:</FormLabel>

      <CardExpiryElement
        className="expiryStripe"
        options={CARD_ELEMENT_OPTIONS}
      />
      <FormLabel>Security code:</FormLabel>

      <CardCvcElement className="cvcStripe" options={CARD_ELEMENT_OPTIONS} />
      {/* </Flex> */}
    </div>
  );
}
