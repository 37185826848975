import React, { useState } from "react";
import {
  Box,
  Spinner,
  Text,
  FormLabel,
  Checkbox,
  Divider,
} from "@chakra-ui/react";
import axios from "axios";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";

import firebase from "utils/firebase/firebase";
import UserDetailsForm from "../UserDetailsForm/index";
import PlanSelect from "../SelectPlan";

import updateUserSubscription from "utils/firebase/subscription/updateUserSubscription";
import { createSubUrl } from "utils/constants/cloudFunctionsUrls";
import getUserData from "utils/firebase/user/getUserData";

import CardInput from "./CardInput";
import { StyledInput, Button, TermsContainer } from "./styles";

function StripeForm(props) {
  const {
    selectedPlan: selectedProduct,
    setPaymentSuccessRes,
    onSubmitSignUp,
    credentials,
    setCredentials,
    selectedPlan,
    handlePlanSelect,
    userError,
    setUserError,
    userSuccess,
    setUserSuccess,
    userIsLoading,
    setUserIsLoading,
  } = props;

  // const selectedProduct = stripeProductsInfo[selectedPlan];

  console.log("credentials", credentials);

  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [nameOnCard, setNameOnCard] = useState("");

  const stripe = useStripe();
  const elements = useElements();

  const setErrorMessage = (errorMessage) => setError(errorMessage);

  const onSubmit = async (event) => {
    event.preventDefault();
    // const userProfileRes = await onSubmitSignUp();

    // if (!userProfileRes) return;

    if (!selectedProduct)
      // return;
      alert("please select a product!");
    if (!stripe || !elements) {
      //! Stripe.js has not yet loaded. // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    console.log(selectedProduct);
    // return;
    setIsLoading(true);
    setErrorMessage("");
    setSuccess(false);

    //! fetching userData from firebase
    // const userData = await getUserData();
    // console.log("currentUserData", userData);
    //! checking if the user data is available or not
    if (_isEmpty(credentials)) {
      alert("no user found please create an account first");
      return;
    }

    //! Generating user info and payment method to send to stripe
    const { createPaymentMethodResult, billing_details, metadata } =
      await createPaymentMethod(credentials);

    // console.log("createPaymentMethodResult", createPaymentMethodResult);

    //! checking if Error while creating paymentMethod
    if (createPaymentMethodResult?.error) {
      console.log(createPaymentMethodResult?.error?.message);
      setErrorMessage(createPaymentMethodResult?.error?.message);
      setSuccess(false);
      setIsLoading(false);
      return;
    }

    //! getting paymentMethod ID
    const paymentMethodID = createPaymentMethodResult?.paymentMethod?.id;

    //! Now make request to backend for creating subscription
    try {
      // const url = "http://localhost:3001/subscription/create";
      const url = createSubUrl;
      const res = await axios.post(url, {
        payment_method_id: paymentMethodID,
        email: credentials?.email,
        selectedProduct: {
          productName: selectedProduct?.productName,
          productPriceId: selectedProduct?.productPriceId,
        },
        billing_details,
        metadata,
      });

      const {
        client_secret,
        success,
        pending_setup_intent,
        customerId,
        // eslint-disable-next-line no-unused-vars
        subscriptionId,
        errorSubscription,
      } = res?.data;
      // console.log("axios res", res);
      //! loggin response from server
      console.log({
        client_secret,
        success,
        pending_setup_intent,
        errorSubscription,
      });

      //! if success is false from server
      if (!success) {
        setErrorMessage(errorSubscription);
        setSuccess(false);
        setIsLoading(false);
        return;
      }

      //! Now confirm CardPayment if using trial period
      const confirmedCardPaymentResult = await stripe.confirmCardSetup(
        pending_setup_intent,
        {
          payment_method: {
            card: elements.getElement(
              CardNumberElement
              // CardExpiryElement,
              // CardCvcElement
            ),
            billing_details,
          },
        }
      );
      //! Now confirm CardPayment if not using trial or price less than $1
      // const confirmedCardPaymentResult = await stripe.confirmCardPayment(
      //   client_secret
      // );
      // console.log("confirm payment result", confirmedCardPaymentResult);
      setPaymentSuccessRes(confirmedCardPaymentResult || null);

      //! if there's some error in confirming payment
      if (confirmedCardPaymentResult?.error) {
        setErrorMessage(confirmedCardPaymentResult?.error?.message);
      }

      //! updating user sub doc in db when there's not any error
      if (!confirmedCardPaymentResult?.error) {
        //! creating user in auth after the sub is successful
        const userProfileRes = await onSubmitSignUp();

        const currentUser = firebase.auth().currentUser;
        const { uid = "" } = currentUser;

        const { password = "", password2 = "", ...rest } = credentials;

        const subData = {
          // ...credentials,
          ...rest,
          stripeCustomerId: customerId,
          subscription: selectedProduct?.subscription,
          // selectedProduct: selectedProduct?.productName,
          // subscriptionId,
        };

        await updateUserSubscription(subData, uid);
        setSuccess(true);

        //! clearing card payment fields
        elements.getElement(CardNumberElement).clear();
        elements.getElement(CardExpiryElement).clear();
        elements.getElement(CardCvcElement).clear();
        setNameOnCard("");
        setCredentials({
          firstName: "",
          lastName: "",
          email: "",
          password: "",
          password2: "",
        });
        window.location.replace(
          "https://www.helloinspections.com/registeredthanks"
        );
      }
    } catch (err) {
      setErrorMessage(err?.message || "something went wrong with server!");
      console.log("axios paymentIntent error", err?.message);
    }
    setIsLoading(false);
  };

  const createPaymentMethod = async (userData) => {
    const name =
      _get(userData, "firstName", "") + " " + _get(userData, "lastName", "");
    const { email } = userData;

    const billing_details = {
      email: email,
      name: name,
      // address: {
      //   city: "Lahore",
      //   country: "pk",
      //   postal_code: "54000",
      //   state: "punjab",
      //   line1: "MM ALam Road",
      // },
    };

    const metadata = {
      email: email,
      name: name,
      uid: _get(userData, "uid", ""),
      metadata: "metadata",
    };

    const createPaymentMethodResult = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(
        CardNumberElement
        // CardExpiryElement,
        // CardCvcElement
      ),
      billing_details: billing_details,
      metadata,
    });

    return { name, billing_details, metadata, createPaymentMethodResult };
  };

  return (
    <Box>
      <form onSubmit={onSubmit} autoComplete="off">
        <UserDetailsForm
          {...{
            credentials,
            setCredentials,
            error: userError,
            setError: setUserError,
            success: userSuccess,
            setSuccess: setUserSuccess,
            isLoading: userIsLoading,
            setIsLoading: setUserIsLoading,
          }}
        />

        <PlanSelect
          selectedPlan={selectedPlan}
          handlePlanSelect={handlePlanSelect}
        />

        <Text mb="10px" fontWeight="bold" mt={{ base: "40px", md: "40px" }}>
          STEP 3
        </Text>
        <Text fontSize="30px">Card details</Text>
        <Divider my="10px" />

        <FormLabel>Cardholder Name:</FormLabel>
        <StyledInput
          name="name"
          type="text"
          placeholder="Name on card"
          required
          w="100%"
          onChange={(e) => setNameOnCard(e.target.value)}
          value={nameOnCard}
          mb="16px"
        />

        <Box my="0px">
          <CardInput />
        </Box>

        {error && (
          <Box py="20px" color="red">
            {error}
          </Box>
        )}

        {userError && (
          <Box py="20px" color="red">
            {userError}
          </Box>
        )}

        {/* <FormLabel>Email:</FormLabel>
        <StyledInput
          name="email"
          type="email"
          placeholder="Email"
          required
          value={userData?.email}
          w="100%"
          onChange={(e) => console.log()}
          disabled
        /> */}

        {success && (
          <Box
            py="20px"
            display="flex"
            alignItems="center"
            flexDir={{ base: "column", md: "row" }}
          >
            <Text color="green" my={{ base: "5px", md: "0px" }}>
              Payment is completed
            </Text>
          </Box>
        )}

        <TermsContainer>
          <Checkbox
            defaultChecked={isChecked}
            checked={isChecked}
            mr="10px"
            onChange={(e) => setIsChecked(e.target.checked)}
          />
          I agree to
          <a href="https://register.helloinspections.com/terms">
            Terms Of Service
          </a>
        </TermsContainer>

        <Text mt="10px">
          Your card will not be charged until your 30 day trial is complete
        </Text>

        <Button
          type="submit"
          disabled={isLoading || userIsLoading}
          opacity={!isLoading ? "1" : "0.5"}
        >
          {isLoading || userIsLoading ? <Spinner color="gray.200" /> : "Create account"}
        </Button>
      </form>
    </Box>
  );
}

export default StripeForm;
