import firebase from "utils/firebase/firebase";

const createCompany = async (userData) => {
  const { email, uid } = userData;

  const dbRef = firebase.database().ref();
  const newCompanyKey = firebase.database().ref().child("companies").push().key;
  console.log({ newCompanyKey });
  await dbRef
    .child("companies")
    .child(newCompanyKey)
    .set({
      // email: "test@gmail.com",
      // users: ["evFd9J6FRibMB42oakD5EENruWC2"],
      email: email,
      users: [uid],
    })
    .then(() => {
      console.log("company created successfully!");
    })
    .catch((err) => {
      console.log("error in creating company", err);
    });

  return newCompanyKey;
};

export default createCompany;
