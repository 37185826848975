import _get from "lodash/get";
import firebase from "./firebase";

const signUpuser = async (email, password, credentials) => {
  if (!email && !password) return null;

  const response = await firebase
    .auth()
    .createUserWithEmailAndPassword(email, password)
    .then(async () => {
      const currentUser = firebase.auth().currentUser;
      await createUserProfile(currentUser, credentials);

      return { success: true };
    })
    .catch((err) => {
      return { success: false, message: _get(err, "message", "") };
    });

  return response;
};

const createUserProfile = async (user, credentials) => {
  const { uid } = user;
  const { email, firstName, lastName } = credentials;
  console.log({ uid });

  await firebase
    .database()
    .ref("users/" + uid)
    .set({
      email,
      firstName,
      lastName,
      // uid,
      // password,
    })
    .then((res) => {
      console.log("user profile created");
    })
    .catch((err) => {
      console.log("error creating user profile", err);
    });
};

export default signUpuser;
