import React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";

import {
  ImgContainer,
  PlanBox,
  GiftIcon,
  BannerPlansContainer,
} from "./styles";

function Banner() {
  return (
    <div>
      <ImgContainer>
        <Box
          color="white"
          position="relative"
          p="15px"
          fontSize="24px"
          lineHeight="26px"
          display={{ base: "none", md: "block" }}
        >
          <a href="https://helloinspections.com/">
            HELLO <br /> INSPECTIONS
          </a>
        </Box>
      </ImgContainer>

      <BannerPlansContainer
        // w="100vw"
        display={{ base: "flex", md: "block" }}
        direction="column"
        alignItems={{ base: "center", md: "" }}
      >
        <Text
          color="white"
          textAlign="center"
          fontSize="2.25rem"
          mb="4rem"
          className="bannerText"
        >
          Create your Hello Inspections account
        </Text>

        <Flex
          justifyContent={{ base: "center", md: "space-evenly" }}
          flexDirection={{ base: "column", md: "row" }}
          marginTop={{ base: "30px", md: "0" }}
        >
          <PlanBox>
            <GiftIcon />
            <Text fontWeight="600" fontSize="1.5em" mb="-8px" mt="-6px">
              Basic Plan
            </Text>
            <Text mb="1rem">$69 / month</Text>
            <ul>
              <li>
                <b>30 Day Free Trial</b>
              </li>
              <li>20 Reports per 30 days</li>
              <li>Full support</li>
              <li>Unlimited features</li>
              <li>Unlimited upgrades</li>
            </ul>
          </PlanBox>

          <PlanBox>
            <GiftIcon />
            <Text fontWeight="600" fontSize="1.5em" mb="-8px" mt="-6px">
              Pro Plan
            </Text>
            <Text mb="1rem">$115 / month</Text>

            <ul>
              <li>
                <b>30 Day Free Trial</b>
              </li>
              <li>Unlimited reports</li>
              <li>Full support</li>
              <li>Unlimited features</li>
              <li>Unlimited upgrades</li>
            </ul>
          </PlanBox>
        </Flex>
      </BannerPlansContainer>
    </div>
  );
}

export default Banner;
