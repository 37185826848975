import styled from "@emotion/styled";
import { Input, Flex } from "@chakra-ui/react";

export const TermsContainer = styled(Flex)`
  margin-top: 20px;
  a {
    color: blue;
  }
`;

export const StyledInput = styled(Input)`
  height: 56px;
  background: white;

  margin-bottom: ${(props) => props.mb};

  :focus {
    border-color: orange;
    box-shadow: none;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Button = styled("button")`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 40px;
  gap: 4px;
  width: 100%;
  height: 56px;
  background: #2779bd;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  margin-top: 20px;
  font-weight: 700;

  opacity: ${(props) => props.opacity};

  :focus-visible {
    outline: none;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;
