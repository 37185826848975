import firebase from "utils/firebase/firebase";

import createCompany from "utils/firebase/company/createCompany";

const updateUserSubscription = async (subscriptionData) => {
  if (!subscriptionData) return;

  const uid = firebase.auth().currentUser.uid;

  const companyId = await createCompany({
    email: subscriptionData?.email,
    uid,
  });
  const payloadData = { ...subscriptionData, isActive: true, companyId };
  console.log({ payloadData, uid });

  // return;
  const dbRef = firebase.database().ref();

  const res = await dbRef
    .child("users")
    .child(uid)
    .update({
      ...payloadData,
    })
    .then(() => {
      console.log("user profile updated!");
      return "success";
    })
    .catch((err) => {
      console.log("error updating user profile", err);
      return "fail";
    });

  return res;
};

export default updateUserSubscription;
