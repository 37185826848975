import React, { useState } from "react";
import { Box } from "@chakra-ui/react";

import StripeForm from "containers/Stripe";
import CreateUser from "utils/firebase/sign_up_user";

import Banner from "./Banner";
import { Container } from "./styles";
import stripeProductsInfo from "./SelectPlan/stripeProductsInfo";

function Home() {
  const [selectedPlan, setSelectedPlan] = useState(stripeProductsInfo["basic"]);
  // eslint-disable-next-line no-unused-vars
  const [paymentSuccessRes, setPaymentSuccessRes] = useState(false);
  const [credentials, setCredentials] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    password2: "",
  });
  const [userError, setUserError] = useState("");
  const [userSuccess, setUserSuccess] = useState(false);
  const [userIsLoading, setUserIsLoading] = useState(false);

  const handlePlanSelect = (plan) => setSelectedPlan(plan);

  const onSubmitSignUp = async () => {
    setUserIsLoading(true);
    setUserSuccess(false);
    setUserError("");
    const { email, password, password2 } = credentials;

    if (!email) {
      setUserError("enter email");
    }

    if (password !== password2) {
      setUserError("password does not match");
      setUserIsLoading(false);

      return;
    }

    console.log(credentials);
    const res = await CreateUser(email, password, credentials);

    if (!res?.success) {
      setUserError(res?.message);
      setUserIsLoading(false);

      return false;
    }

    if (res?.success) {
      setUserSuccess(true);
      setUserIsLoading(false);

      return true;
    }
  };

  return (
    <div>
      <Banner />

      <Box px={{ base: "2rem", md: "6.2rem" }} py="3rem">
        <Container>
          <Box mt="40px">
            <StripeForm
              selectedPlan={selectedPlan}
              setPaymentSuccessRes={setPaymentSuccessRes}
              onSubmitSignUp={onSubmitSignUp}
              {...{
                credentials,
                setCredentials,
                userError,
                setUserError,
                userSuccess,
                setUserSuccess,
                userIsLoading,
                setUserIsLoading,
                selectedPlan,
                handlePlanSelect,
              }}
            />
          </Box>
        </Container>
      </Box>
    </div>
  );
}

export default Home;
