import styled from "@emotion/styled";
import { Box } from "@chakra-ui/react";

export const Container = styled(Box)`
  padding: 3rem;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
  background: white;

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;
