const stripeProductsInfo = {
  basic: {
    productName: "Monthly Basic",
    // productPriceId: "Basic",
    productPriceId: "price_1NrWF8InI75mtNa1PlsaISBR",
    // productPriceId: "price_1Lv2AOI62CmuoLGMzbK3MqTz",
    subscription: "Basic",
  },
  pro: {
    productName: "Monthly Pro",
    // productPriceId: "price_1Lv2CGI62CmuoLGMcYz7lsdF",
    // productPriceId: "Pro",
    productPriceId: "price_1NrWFYInI75mtNa1H32kX8tD",
    subscription: "Pro",
  },
};

export default stripeProductsInfo;
