import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import StripeSubscriptionPage from "components/Home/StripeSubscription";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

function StripeSubscription(props) {
  return (
    <Elements stripe={stripePromise}>
      <StripeSubscriptionPage {...props} />
    </Elements>
  );
}

export default StripeSubscription;
