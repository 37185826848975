import styled from "@emotion/styled";
import { Button } from "@chakra-ui/react";

export const StyledButton = styled(Button)`
  background: ${(props) => (props.background ? props.background : "white")};
  color: ${(props) => (props.color ? props.color : "black")};
  border: 1px solid #dae1e7;
  font-weight: 300;
  :focus {
    box-shadow: none;
  }
  :active {
    box-shadow: none;
  }
  padding: 25px 30px;
`;
